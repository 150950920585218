<template>
  <div class="list-group" v-if="realEstateData">
    <label>Adresy nieruchomości:</label>
    <div class="address-data-container col-xs-12 list-group-item"
         :key="`address-${addressIndex}`"
         v-for="(address, addressIndex, index) in realEstateData"
    >
      <label for="address-purpose-data">Adres nieruchomości: {{ index + 1 }}</label>
      <div id="address-data">
        <ul class="list-group">
          <li
            class="list-group-item list-group-item-overflowed"
            :key="`address-${addressIndex}-addressField-${addressFieldIndex}`"
            v-for="(addressField, addressFieldIndex) in filterAddressFields(address)"
          >
            <label for="">{{ addressFieldIndex }}:</label>
            {{ addressField }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import addressesMixin from '../addressesMixin.js'

export default {
  name: "RealEstateAddressesAppraisal",
  mixins: [addressesMixin],
  props: {
    realEstateData: {}
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
