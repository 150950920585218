<template>
  <div>
    <div
      class="list-group-item list-group-item-overflowed "
      :key="`creditPurposeappraisalTaskRealEstate-${creditPurposeappraisalTaskRealEstateId}`"
      v-for="(creditPurposeappraisalTaskRealEstate, creditPurposeappraisalTaskRealEstateId, creditPurposeappraisalTaskRealEstateIndex) in creditPurposeappraisalTask.realEstates">
      <div>
        <label>
        Nieruchomość: {{ creditPurposeappraisalTaskRealEstateIndex + 1 ? creditPurposeappraisalTaskRealEstateIndex + 1 : creditPurposeappraisalTaskRealEstateId + 1 }}
        </label>
      </div>
      <div>
        <label>
          Typ:
        </label>
        <template v-if="creditPurposeappraisalTaskRealEstate && creditPurposeappraisalTaskRealEstate.kinds && creditPurposeappraisalTaskRealEstate.kinds.length !== 0">
        <label v-for="(kind, kindIndex) in creditPurposeappraisalTaskRealEstate.kinds " :key="`kind-${creditPurposeappraisalTaskRealEstateIndex + 1}-${kindIndex + 1}`">
          {{ getKindLabel(kind) || 'b.d' }}
        </label>
        </template>
        <label v-else>b.d</label>
      </div>
    </div>
  </div>
</template>

<script>
import realEstateKindMixin from '../realEstateKindMixin.js'

export default {
  name: 'RealEstateKinds',
  mixins: [realEstateKindMixin],
  props: {
    creditPurposeappraisalTask: {
      type: Object,
      required: true
    }
  },
  methods: {
    getRealEstateTypes (creditPurposeappraisalTaskRealEstate) {
      return creditPurposeappraisalTaskRealEstate.kinds ? `${creditPurposeappraisalTaskRealEstate.kinds.map(kind => this.getKindLabel(kind)).join(', ')}.` : ''
    }
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
