<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    @show="open"
    @close="$emit('close')"
    @beforeSubmit="bool => beforeSubmitTrigger = bool"
  >
    <div class="form-group">
      <select-type
        v-model="commentType"
        name="commentType"
        :label="$t('pages.taskDetails.comments.modal.selectLabel')"
        :required="true"
        :options="commentTypesOptions"
        :empty-first="false"
        :service="service"
      />
    </div>
    <form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
import _ from 'lodash'
import BaseFormModal from '../../../../share/modal/BaseFormModal'
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from '../form/Comment'
import api from '../../../../../api'
import ValidatorReset from '../../../../share/mixins/ValidatorReset'
import SelectType from '../../../../share/form/type/SelectType'
import WebStorage from '../../../../share/WebStorage'
import CommentMixin from '../CommentMixin'

export default {
  components: {
    SelectType,
    FormModal,
    BaseFormModal
  },
  mixins: [
    FormModalMixin,
    ValidatorReset,
    CommentMixin
  ],
  props: {
    service: { type: String, required: true, default: '' },
    taskType: { type: String, default: 'main' }
  },
  data () {
    return {
      sending: false,
      title: this.$t('pages.taskDetails.comments.modal.addModalTitle'),
      commentType: null,
      commentTypes: [],
      commentTypesOptions: [],
      trans: {
        private: { value: 1, label: this.$t('pages.taskDetails.comments.modal.private'), type: 'private' },
        internal: { value: 2, label: this.$t('pages.taskDetails.comments.modal.internal'), type: 'internal' },
        public: { value: 4, label: this.$t('pages.taskDetails.comments.modal.public'), type: 'public' }
      }
    }
  },
  mounted () {
    this.getCommentTypes()
  },
  methods: {
    getCommentTypes () {
      const actions = WebStorage.getSecurityActions()

      if (this.$isWithClients(this.service)) {
        let actionNamePrefix = this.taskType === 'main' ? 'get_comments__main' : 'get_comments__appraisal'
        let client = this.$route.meta.client
        this.commentTypes = this.getAllowedCommentTypes(actions[this.service], actionNamePrefix, client)
        this.commentType = this.commentTypes[0].type
        this.commentTypesOptions = this.commentTypes.map((comment) => {
          comment.value = comment.type
          return comment
        })
      } else {
        this.commentTypesOptions = actions[this.service].filter(action => this.taskType === 'main' ? action.includes('post_comment_main') : action.includes('post_comment_appraisal')).map((action) => {
          return this.trans[action.split('_')[3]]
        })
        this.commentType = this.commentTypesOptions[0].value
      }
      // Set initial model value of first select item
    },
    submitForm () {
      let id = (this.taskType === 'appraisal') ? this.$route.params.appraisalTaskId : this.$route.params.id
      let comment = this.createDataToSend()
      this.$isWithClients(this.service) ? this.submitCommentClients(id, comment) : this.submitCommentBase(id, comment)
    },
    submitCommentBase (id, comment) {
      if (this.sending === true) {
        this.$notify({
          type: 'info',
          title:  this.$t('pages.taskDetails.comments.modal.commentSending'),
          text: ''
        })
        return
      }
      this.sending = true
      api.request(this.service, 'post', `/tasks/${id}/${this.taskType}/comments/${this.commentTypesOptions.filter(type => type.value.toString() === this.commentType.toString())[0].type}`, comment)
        .then((response) => {
          this.$notify({
            type: 'success',
            text: this.$t('pages.taskDetails.comments.modal.commentSuccesfullyAddeed')
          })
          comment.id = response.data.id
          this.$emit('success')
          this.$emit('close')
          this.$events.$emit('addComment', comment)
          this.sending = false
        })
        .catch(() => {
          this.sending = false
          this.$notify({
            type: 'error',
            title: this.$t('error.default'),
            text: this.$t('pages.taskDetails.comments.modal.commentSendingError')
          })
        })
    },
    submitCommentClients (id, comment) {
      if (this.sending === true) {
        this.$notify({
          type: 'info',
          title: this.$t('pages.taskDetails.comments.modal.commentSuccesfullyAddeed'),
          text: ''
        })
        return
      }
      this.sending = true
      let data = {
        taskId: id,
        content: this.model.message
      }
      data[`${this.commentType}`] = true
      data[`${this.taskType}`] = true

      let commentTypeList = this.commentTypes.filter(comment => comment.type === this.commentType )
      let commentTypeElement = _.isEmpty(commentTypeList) ? {} : commentTypeList[0]

      if (commentTypeElement !== null && commentTypeElement.client !== null) {
        data['client'] = commentTypeElement.client
      }

      api.request(this.service, 'post', `/comments`, data)
        .then((response) => {
          this.$notify({
            type: 'success',
            text: this.$t('pages.taskDetails.comments.modal.commentSuccesfullyAddeed')
          })
          let location = response.headers.location.split('/')
          let commentId = location[location.length - 1]
          comment.id = commentId
          this.$emit('success')
          this.$emit('close')
          this.$events.$emit('addComment', comment)
          this.sending = false
        })
        .catch(() => {
          this.sending = false
          this.$notify({
            type: 'error',
            title: this.$t('error.default'),
            text: this.$t('pages.taskDetails.comments.modal.commentSendingError')
          })
        })
    },
    createDataToSend () {
      return {
        type: this.commentType,
        content: this.model.message
      }
    }
  }
}
</script>
