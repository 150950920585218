<template>
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_customer'})">
      <CustomerData :customer-data="customerData"></CustomerData>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})">
      <SummaryField v-if="$auth.isAllowed({service: this.service, action: 'get_summary_main_credit_purpose'})"
                    :data="creditPurposeData.creditPurpose" :label="'Wartość do określenia'"></SummaryField>

      <SummaryField :data="creditPurposeData.typeOfStudy" :label="'Rodzaj opracowania'"></SummaryField>
      <SummaryField :data="creditPurposeData.kinds" :label="'Rodzaj nieruchomości'"></SummaryField>
      <SummaryField :data="creditPurposeData.market" :label="'Rynek'"></SummaryField>
      <SummaryField :data="creditPurposeData.priority" :label="'Priorytet'"></SummaryField>
      <div class="col-xs-12">
        <label>Typy nieruchomości:</label>
        <RealEstateKinds  v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})" :credit-purposeappraisal-task="task"></RealEstateKinds>
      </div>
      <DeliveryMethods v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})" :credit-purpose-data="creditPurposeData"/>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_real_estate'})">
      <RealEstateAddressesAppraisal :real-estate-data="realEstateData"></RealEstateAddressesAppraisal>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
         v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_invoices'}) && appraisalInvoicesData !== null">
      <label for="address-purpose-data">FV zlecenia:</label>
      <InvoiceAppraisal :appraisal-invoice="appraisalInvoicesData" :appraisal-invoice-index="'0'" ></InvoiceAppraisal>
    </div>
  </div>
</template>

<script>
import summaryRequests from './summaryRequests.js'
import CustomerData from './components/CustomerData.vue'
import RealEstateAddressesAppraisal
  from './components/RealEstateAddressesAppraisal.vue'
import DeliveryMethods from './components/DeliveryMethods.vue'
import InvoiceAppraisal from './components/InvoiceAppraisal.vue'
import RealEstateKinds from './components/RealEstateKinds.vue'
import SummaryField from '@/components/bundles/intbwn/share/summary/components/SummaryField.vue'

export default {
  name: 'Summary',
  components: {
    SummaryField,
    RealEstateKinds,
    InvoiceAppraisal,
    DeliveryMethods,
    RealEstateAddressesAppraisal,
    CustomerData
  },
  mixins: [summaryRequests],
  props: {
    taskId: {default: () => null},
    taskGroup: {},
    service: {type: String, default: () => ''},
    task: {
      required: true
    },
    summaryTabOpened: {type: Boolean}
  },
  watch: {
    summaryTabOpened: function (newValue, value) {
      if (newValue) {
        this.loadData()
      }
    }
  },
  data () {
    return {
      customerData: null,
      creditPurposeData: null,
      realEstateData: null,
      appraisalInvoicesData: null
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_customer'
      }, () => this.getCustomerData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_credit_purpose'
      }, () => this.getCreditPurposeData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_real_estate'
      }, () => this.getRealEstateData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_invoices'
      }, () => this.getAppraisalInvoicesData())
    },
    handleInvoicesUpdated () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_invoices'
      }, () => this.getAppraisalInvoicesData())
    }
  }
}
</script>

<style scoped>

</style>
