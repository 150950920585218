<template>
  <form>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('message'), 'has-success' : !vErrors.first('message') && value['message'] }]"
    >
      <label for="message">
        {{$t('pages.taskDetails.comments.modal.content')}}
      </label>
      <textarea
        id="message"
        v-model="value['message']"
        v-validate="{rules: {required: true, max: 2048}}"
        name="message"
        class="form-control"
        cols="60"
        rows="6"
        :placeholder="$t('pages.taskDetails.comments.modal.placeholder')"
        required="true"
        :data-vv-as="$t('pages.taskDetails.comments.modal.dataVvAs')"
      />
      <span
        v-show="vErrors.has('message')"
        class="help-block"
      >{{ vErrors.first('message') }}</span>
    </div>
  </form>
</template>

<script>
import FormModalValidation from '../../../../share/form/FormModalValidation'

export default {
  mixins: [
    FormModalValidation
  ],
  props: {
    value: { type: Object, required: true }
  }
}
</script>
