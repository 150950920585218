<template>
    <div v-if="creditPurposeData" class="credit-purpose-data-container col-xs-12" >
      <label for="credit-purpose-deliveryMethods-data">Wysyłka operatu:</label>
      <div v-if="creditPurposeData.deliveryMethods" id="credit-purpose-deliveryMethods-data">
        <ul class="list-group" v-if="creditPurposeData.deliveryMethods.length !== 0">
          <li
            class="list-group-item list-group-item-overflowed"
            :key="`delivery-method-${creditPurposeEl}-${index}`"
            v-for="(creditPurposeEl, index) in creditPurposeData.deliveryMethods"
          >
            {{ getName(creditPurposeEl) }}
          </li>
        </ul>
        <ul class="list-group" v-else>
          <li
            class="list-group-item list-group-item-overflowed"
          >
            Brak celi kredytowania.
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  name: 'DeliveryMethods',
  props: {
    creditPurposeData: {}
  },
  methods: {
    getName(deliveryMethod) {
      const mapping = {
        'Post': 'Poczta',
        'Email': 'Email'
      }
      return mapping.hasOwnProperty(deliveryMethod) ? mapping[deliveryMethod] : deliveryMethod
    }
  }
}
</script>

<style scoped>
.list-group-item-overflowed {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
