<script>
import FormModalMixin from '../../../../share/modal/FormModalMixin'
import FormModal from '../form/ChangeVisionForm'
import api from '../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    service: { type: String, default: '' },
    taskNumber: {},
    taskId: {}
  },
  data () {
    return {
      title: 'Zmień datę wizji',
      actionType: 1, // default: nowy wpis
      sending: false
    }
  },
  methods: {
    submitForm () {
      if (this.sending === false) {
        this.sending = true
        let url = this.$isWithClients(this.service) ? '/tasks/vision-date' : `/tasks/vision-date`
        api.request(this.service, 'put', url, this.createDataToSend())
          .then((response) => {
            this.$notify({
              type: 'success',
              text: 'Zmieniono datę wizji'
            })
            this.$emit('success')
            this.$emit('close')
            this.$emit('refresh')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie można zmienić daty wizji'
            })
          })
      }
    },
    createDataToSend () {
      let data = {
        date: this.model.date + ' ' + this.model.time,
      }
      if (this.$isWithClients(this.service)) {
        data['taskId'] = this.taskId
      }
      return {data: data}
    }
  }
}
</script>
