<template>
  <ul
    v-if="relatedTasks"
    class="list-unstyled task-header--details row"
  >
    <li class="relatedTasks-wrapper">
      <div style="position: relative; height: auto; width: 12%;">
        <label
          class="align-right"
          style="display: block;"
        >Powiązane: </label>
        <button
        v-show="this.limitedList && this.limitedList.length >= 19"
        class="showMore-button"
        @click="showFullRelatedTasks =! showFullRelatedTasks" >
        <i v-if="showFullRelatedTasks" class="feather-arrow-up" /> <i v-else class="feather-arrow-down" />
        {{showFullRelatedTasks ? 'Mniej': "Więcej"}}
        </button>
      </div>
      <span
        :id="`${task.id}-relatedTasks`"
        class="relatedTasks">
            <template
              v-for="(relatedTask, index) in limitedList"
            >
              <popover
                v-if="relatedTasks && relatedTasks.length > 0"
                placement="bottom"
                :title="`${relatedTask.number || relatedTask.id}`"
                trigger='hover'
                :key="`popver-${relatedTask.id}`"
              >
              <span
                v-if="relatedTasks && relatedTasks.length "
                :key="relatedTask.id"
                :id="`span${task.id}-related-task-${relatedTask.id}`"
              >
              <a
                v-if="relatedTasks.length === 1"
                :id="`${task.id}-related-task-link-${relatedTask.number || relatedTask.id}`"
                class="related-task-link"
                @click="redirectToRelatedTask(relatedTask.id)"
              > {{ relatedTask.number || relatedTask.id }} </a>
              <a
                v-if="relatedTasks.length > 1"
                :id="`${task.id}-related-task-link-${relatedTask.number || relatedTask.id}`"
                class="related-task-link"
                @click="redirectToRelatedTask(relatedTask.id)"
              > {{ relatedTask.number || relatedTask.id }}</a>{{ getComa(index) }}
            <div>
          </div>
            </span>
                <template slot="popover">
                    <ul style="list-style: none">
                      <li v-if="relatedTask.icClaimNumber"><label>Nr TU:</label> {{relatedTask.icClaimNumber}}</li>
                      <li v-if="relatedTask.policyNumber"><label>Polisa:</label> {{relatedTask.policyNumber}}</li>
                      <li v-if="relatedTask.registrationNumber"><label>Poszkodowany:</label>{{relatedTask.registrationNumber}}</li>
                      <li v-if="relatedTask.accidentParticipantsRegistrationNumber"><label>Sprawca:</label> {{relatedTask.accidentParticipantsRegistrationNumber}}</li>
                    </ul>
                  </template>
              </popover>
              <span v-if="relatedTasks.length === 0" :key="relatedTask.id">b.d.</span>
            </template>
        </span>
    </li>
  </ul>

</template>

<script>
  import {Popover} from 'uiv'

  export default {
    name: 'RelatedTasks',
    props: {
      relatedTasks: {type: Array, default: () => []},
      service: {type: String, default: () => ''},
      workflowName: {type: String, default: () => ''},
      task: {type: Object, default: () => {}}
    },
    components: {
      popover: Popover
    },
    data () {
      return {
        showFullRelatedTasks: false,
      }
    },
    computed: {
      client () {
        return this.$route.meta.client
      },
      limitedList() {
      if (this.relatedTasks.length > 19 && !this.showFullRelatedTasks ) {
      return this.relatedTasks.slice(0, 19) }
      return this.relatedTasks
  }
    },
    methods: {
      redirectToRelatedTask (id) {
        this.$isWithClients(this.service) ? this.$router.push({ name: `${this.service}_${this.client}_${this.workflowName}_task_details`, params: { id } })
          : this.$router.push({ name: `${this.service}_${this.workflowName}_task_details`, params: { id } })
      },
      getComa (index) {
        return  (index === this.relatedTasks.length - 1) ? '.' : (this.limitedList && index === this.limitedList.length - 1) ? '...' : ','
      }
    }
  }
</script>

<style scoped>
.relatedTasks-wrapper{
  display: flex;
}
.relatedTasks {
  display:flex;
  flex-wrap: wrap;
}
.showMore-button {
  border: 1px solid rgba(66, 165, 246, 0.2);
  border-radius: 4px;
  position: absolute;
  right: 5px;
  background-color: white;
  top: 23px;
  font-size: 0.9em;
  right: 6px;
  width: 72px;
}
.showMore-button:hover{
background-color:  #42a5ff;
color: white;
opacity: 0.8;
}
</style>
